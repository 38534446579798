import * as React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import * as colours from '../styling/colours';
import { Container, ContainerPage } from './container';
import Link from 'gatsby-link';

type Props = {
  crumbs: Array<{ url: string, name: string }>,
};

export const Breadcrumbs = (props: Props) => (
  <div>
    <Helmet>
      <script type="application/ld+json">
      {`
        {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "item": {
                "@id": "http://www.pre-drinks.com",
                "name": "Home"
              }
            },
            ${props.crumbs.map((c, index) => `
            {
              "@type": "ListItem",
              "position": ${index + 2},
              "item": {
                "@id": "http://www.pre-drinks.com/${c.url}",
                "name": "${c.name}"
              }
            }
            `)}
          ]
        }
      `}
      </script>
    </Helmet>
    <BreadcrumbWrapper>
        <ContainerPage>
          <Container>
            <BreadcrumbLink to="/">Home</BreadcrumbLink>
            {props.crumbs.map(crumb => (
              <span>
                <DividingSlash>/</DividingSlash>
                <BreadcrumbLink to={`/${crumb.url}`}>{crumb.name}</BreadcrumbLink>
              </span>
            ))}
          </Container>
        </ContainerPage>
    </BreadcrumbWrapper>
  </div>
);

const BreadcrumbWrapper = styled.div`
  background-color: #e1e1e1;
  padding: 5px 0;
`;

const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  color: ${colours.niceBlue};
  font-size: 0.9em;
  line-height: 28px;

  &:hover {
    opacity: 0.75;
  }
`;

const DividingSlash = styled.span`
  margin: 0 10px;
`;
