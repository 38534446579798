import * as React from 'react';
import styled from 'styled-components';
import { GameDetails } from './game';
import { Helmet } from 'react-helmet';
import { Breadcrumbs } from './breadcrumbs';
import * as fonts from '../styling/fonts';
import { ContainerPage, Container, mobile, tablet } from './container';
import { niceBlue } from '../styling/colours';
import Layout from './layouts';

type Props = {
  gameDetails: GameDetails,
};

export const GamePage = ({ gameDetails }: Props) => (
  <Layout>
    <Helmet>
        <title>How to play {gameDetails.name} drinking game | pre-drinks.com</title>
        <meta
            name="description"
            content={gameDetails.metaDescription}
        />
    </Helmet>
    <Breadcrumbs crumbs={[
      { url: 'DrinkingGames', name: 'Drinking Games' },
      { url: `DrinkingGames/${gameDetails.category}DrinkingGames`, name: `${gameDetails.category} Drinking Games` },
      { url: `DrinkingGames/${gameDetails.pageName}`, name: gameDetails.name },
    ]} />
    <Wrapper>
        <ContainerPage>
            <Container>
                <TopSection>
                  <GameImage src={require(`../images/icons/gameIcons/${gameDetails.pageName}.svg`)} alt={`${gameDetails.imageName}.`} />
                  <NameAndPlayers>
                    <GameName>{gameDetails.name}</GameName>
                    <Players><PlayersIcon src={require('../images/users-solid.svg')} alt="Players." /> Required: {gameDetails.playersRequired}</Players>
                    <Players><PlayersIcon src={require('../images/users-solid.svg')} alt="Players." /> Recommended: {gameDetails.playersRecommended}</Players>
                  </NameAndPlayers>
                </TopSection>
                {gameDetails.setup && (
                  <Setup>
                    <h2>
                      Setup
                    </h2>
                    {gameDetails.setup}
                  </Setup>
                )}
                <HowToPlay>
                  <h1>
                    How to play {gameDetails.name}
                  </h1>
                  {gameDetails.howToPlay}
                </HowToPlay>
                <ImageCredit href={gameDetails.imageUrl} target="_blank">{gameDetails.imageName} image by {gameDetails.imageAuthor}</ImageCredit>
            </Container>
        </ContainerPage>
    </Wrapper>
  </Layout>
);

const Wrapper = styled.div`
    padding-top: 40px;
    padding-bottom: 30px;

    p {
      line-height: 1.75em;
    }

    @media (max-width: ${tablet}px) {
      padding-top: 25px;
    }

    @media (max-width: ${mobile}px) {
      padding-top: 15px;
    }
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: ${mobile}px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const NameAndPlayers = styled.div`
  padding-top: 20px;

  @media (max-width: ${mobile}px) {
    text-align: center;
    padding-top: 0;
  }
`;

const PlayersIcon = styled.img`
    height: 20px;
    margin-right: 10px;
    opacity: 0.7;
`;

const Players = styled.div`
  margin-bottom: 10px;
`;

const GameImage = styled.img`
  width: 200px;
  margin-right: 40px;

  @media (max-width: ${mobile}px) {
    margin-bottom: 15px;
    margin-right: 0;
    width: 150px;
  }
`;

const GameName = styled.h2`
  font-size: 2em;
  font-family: ${fonts.headerFont};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  opacity: 0.75;
  
  @media (max-width: ${mobile}px) {
    text-align: center;
    display: inline-block;
  }
`;

const Setup = styled.div`
  margin-top: 35px;

  p {
    margin: 0 0 15px 0;
  }

  h2 {
    font-size: 2em;
    font-family: ${fonts.headerFont};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    opacity: 0.75;
  }

  @media (max-width: ${mobile}px) {
    margin-top: 10px;
  }
`;

const HowToPlay = styled.div`
  margin-top: 35px;

  h1 {
    opacity: 0.75;
    margin-bottom: 10px;
  }

  p {
    margin: 0 0 15px 0;
  }

  @media (max-width: ${mobile}px) {
    margin-top: 10px;
  }
`;

const ImageCredit = styled.a`
  display: inline-block;
  margin-top: 30px;
  opacity: 0.5;
  color: ${niceBlue};

  &:hover {
    opacity: 0.8;
  }
`;
